@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap");
/* Variables */
/* Inputs, Buttons */
/* Fonts */
/* Border & Radius */
/* Buttons, Inputs and Labels */
.btn {
  border: 1px solid;
  background-color: transparent;
  border-radius: 3px;
  display: inline-block;
  width: auto;
  padding: 8px 15px;
  font-size: 1rem;
  outline: none;
  cursor: pointer;
  font-weight: 400;
  text-transform: uppercase;
  transition: 0.3s;
}
.btn:focus {
  display: none;
}
.btn:disabled {
  cursor: not-allowed;
}
.btn.primary-btn {
  border-color: #9F3037;
  color: #fff;
  background-color: #9F3037;
}
.btn.primary-btn:hover {
  transition: 0.3s;
  background-color: transparent;
  color: #9F3037;
}
.btn.secondary-btn {
  border-color: #313E47;
  color: #fff;
  background-color: #313E47;
}
.btn.secondary-btn:hover {
  transition: 0.3s;
  background-color: transparent;
  color: #18374D;
}
.btn.white-btn {
  border-color: #fff;
  color: #313E47;
  background-color: #fff;
}
.btn.white-btn:hover {
  transition: 0.3s;
  border-color: #313E47;
  background-color: #313E47;
  color: #fff;
}
.btn.tab-btn {
  padding: 6px 15px;
  transition: 0.3s;
}
.btn.tab-btn:hover {
  background-color: #9F3037;
  color: #fff;
}
.btn.tab-btn.active {
  background-color: #9F3037;
  color: #fff;
  transition: 0.3s;
}

/* Labels */
label {
  display: block;
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 5px;
}

/* Inputs */
input,
textarea,
select {
  padding: 10px 15px;
  border: 1px solid #eee;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  font-size: 0.8rem;
  font-family: "Poppins", "Sans-serif";
}
input.border-dark,
textarea.border-dark,
select.border-dark {
  border-color: #282b2d;
}

input::placeholder,
textarea::placeholder,
select::placeholder {
  color: #282b2d;
}

.input-spacer {
  margin: 8px 0;
}

/* Forms */
.form-fields {
  margin: 20px 0;
}

.field-group,
.field-group-2,
.field-group-3,
.field-group-4 {
  width: 100%;
  position: relative;
}

@media (min-width: 768px) {
  .field-group-2 {
    width: calc(50% - 10px);
  }

  .field-group-3 {
    width: calc(50% - 10px);
  }

  .field-group-4 {
    width: calc(50% - 10px);
  }
}
@media (min-width: 992px) {
  .field-group-3 {
    width: calc(33% - 10px);
  }
}
@media (min-width: 1200px) {
  .field-group-4 {
    width: calc(25% - 10px);
  }
}
p.error-text {
  font-size: 0.7rem;
  color: #9F3037;
  text-align: right;
  margin-bottom: 5px;
}

.icon {
  width: 24px;
}

.mt-space {
  margin-top: 65px;
}
@media (min-width: 992px) {
  .mt-space {
    margin-top: 150px;
  }
}
@media (min-width: 992px) and (min-width: 1400px) {
  .mt-space {
    margin-top: 85px;
  }
}

.bkg-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.bkg-image.percent {
  background-size: 100%;
}

.normal-lh {
  line-height: 1.2;
}

/* Layout */
.disflex {
  display: flex;
}
.disflex.j_between {
  justify-content: space-between;
}
.disflex.j_start {
  justify-content: flex-start;
}
.disflex.j_center {
  justify-content: center;
}
.disflex.j_end {
  justify-content: flex-end;
}
.disflex.a_center {
  align-items: center;
}
.disflex.a_start {
  align-items: flex-start;
}
.disflex.a_end {
  align-items: flex-end;
}
.disflex.f_wrap {
  flex-wrap: wrap;
}
.disflex.flex-col-rev {
  flex-direction: column-reverse;
}
@media (min-width: 992px) {
  .disflex.flex-col-rev {
    flex-direction: unset;
  }
}
.disflex.flex-row-rev {
  flex-direction: row-reverse;
}
@media (min-width: 992px) {
  .disflex.flex-row-rev {
    flex-direction: unset;
  }
}

.wrapper {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
}
@media (min-width: 1440px) {
  .wrapper {
    max-width: 1400px;
  }
}
@media (min-width: 1440px) and (min-width: 1640px) {
  .wrapper {
    max-width: 1600px;
  }
}

.inner-wrapper {
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 0 20px;
}
@media (min-width: 1200px) {
  .inner-wrapper {
    max-width: 1000px;
    padding: 0;
  }
}
@media (min-width: 1200px) and (min-width: 1400px) {
  .inner-wrapper {
    max-width: 1200px;
  }
}

.eighty,
.seventy-five,
.seventy,
.sixty,
.forty,
.thirty,
.twenty-five,
.twenty,
.half,
.third,
.quater {
  width: 100%;
}

@media (min-width: 768px) {
  .quater {
    width: calc(50% - 10px);
  }
}
@media (min-width: 992px) {
  .eighty {
    width: calc(80% - 10px);
  }

  .seventy-five {
    width: calc(75% - 10px);
  }

  .seventy {
    width: calc(70% - 10px);
  }

  .sixty {
    width: calc(60% - 10px);
  }

  .forty {
    width: calc(40% - 10px);
  }

  .thirty {
    width: calc(30% - 10px);
  }

  .twenty-five {
    width: calc(25% - 10px);
  }

  .twenty {
    width: calc(20% - 10px);
  }

  .half {
    width: calc(50% - 10px);
  }

  .third {
    width: calc(33% - 10px);
  }
}
@media (min-width: 1200px) {
  .quater {
    width: calc(25% - 10px);
  }
}
main {
  box-sizing: border-box;
}
@media (min-width: 1200px) {
  main .dashboard {
    max-width: calc(100% - 150px);
    margin: 0 auto;
  }
}

/* Typography */
body {
  margin: 0;
  font-family: "Poppins", "Sans-serif";
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

body::-webkit-scrollbar {
  display: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  color: #282b2d;
  margin-bottom: 20px;
  line-height: 1;
  margin-top: 0;
}

h1 {
  font-size: 28px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 15px;
}

p {
  color: #282b2d;
  font-family: "Poppins", "Sans-serif";
  line-height: 1.4;
  font-size: 0.9rem;
}

p.medium-text {
  font-size: 0.9rem;
}

p.sub-heading {
  font-size: 1.2rem;
  margin-bottom: 15px;
  margin-top: 0;
  line-height: 1.4;
  font-weight: 500;
}

label {
  margin-bottom: 10px;
  color: #232f34e9;
}

@media (min-width: 768px) {
  h1 {
    font-size: 42px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 32px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 22px;
  }

  p.sub-heading {
    font-size: 1.4rem;
  }
}
@media (min-width: 1200px) {
  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 50px;
  }

  h3 {
    font-size: 38px;
  }

  h4 {
    font-size: 34px;
  }

  h5 {
    font-size: 30px;
  }

  h6 {
    font-size: 26px;
  }

  p.sub-heading {
    font-size: 1.6rem;
  }
}
.thin {
  font-weight: 300;
}

a {
  color: #9F3037;
  text-decoration: none !important;
  font-size: 0.9rem;
}

span {
  font-size: 0.9rem;
}

a:hover {
  color: #9F3037;
}

/* Scaffolding
  =============== */
p,
ol,
ul,
dl,
table {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul li ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

ol li ol {
  margin: 0;
  padding: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
}

.full-container {
  max-width: 100%;
  padding: 0 1rem;
}

ul.menu li a {
  color: #313E47;
}

main .dashboard .box-info {
  min-height: auto;
  margin-bottom: 40px;
  box-sizing: border-box;
  background-color: #fff;
}
main .dashboard .box-info.chart-mobile {
  box-shadow: 0 2px 6px #00000019;
  padding: 20px;
  border-radius: 15px;
}
@media (min-width: 1400px) {
  main .dashboard .box-info.chart-mobile {
    padding: 30px;
  }
}
@media (min-width: 1400px) and (min-width: 1600px) {
  main .dashboard .box-info.chart-mobile {
    padding: 40px;
  }
}
@media (min-width: 992px) {
  main .dashboard .box-info.normal {
    box-shadow: 0 2px 6px #00000019;
    padding: 20px;
    border-radius: 15px;
  }
}
@media (min-width: 992px) and (min-width: 1400px) {
  main .dashboard .box-info.normal {
    padding: 30px;
  }
}
@media (min-width: 992px) and (min-width: 1400px) and (min-width: 1600px) {
  main .dashboard .box-info.normal {
    padding: 40px;
  }
}
@media (min-width: 992px) {
  main .dashboard .box-info {
    min-height: 350px;
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  main .dashboard .box-info {
    min-height: 380px;
  }
}
main .dashboard .box-info .user-info {
  max-width: 400px;
}
@media (min-width: 1200px) {
  main .dashboard .box-info .user-info {
    margin-top: auto;
  }
}
main .dashboard .box-info .admin-actions {
  margin-top: 20px;
}
main .dashboard .box-info .admin-actions .action {
  margin: 10px 0;
  cursor: pointer;
}
main .dashboard .box-info .admin-actions .action .icon-wrapper {
  background-color: #E3EEF5;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  transition: 0.3s;
  margin-right: 10px;
}
main .dashboard .box-info .admin-actions .action .icon-wrapper img {
  width: 14px;
}
main .dashboard .box-info .admin-actions .action:hover .icon-wrapper {
  transition: 0.3s;
  background-color: #ccc;
}
main .dashboard .box-info .admin-actions .action span {
  color: #313E47;
  text-transform: uppercase;
  font-weight: 600;
}
main .dashboard .box-info .admin-actions .search {
  position: relative;
}
main .dashboard .box-info .admin-actions .search span.search-btn {
  position: absolute;
  top: 10px;
  right: 15px;
}
main .dashboard .box-info .box-wrapper {
  padding: 20px;
}
@media (min-width: 992px) {
  main .dashboard .box-info h3 {
    font-size: 32px;
  }
}
@media (min-width: 992px) and (min-width: 1400px) {
  main .dashboard .box-info h3 {
    margin-bottom: 20px;
  }
}
@media (min-width: 1200px) {
  main .dashboard .actions {
    display: flex;
  }
}
main .dashboard .ds-top {
  margin-top: 50px;
}
main .dashboard .overall-info {
  background-image: url("/assets/images/super-admin/overall-info-bgk.svg");
}
@media (min-width: 1400px) {
  main .dashboard .pad-left {
    padding-left: 100px;
  }
}
@media (min-width: 1400px) and (min-width: 1600px) {
  main .dashboard .pad-left {
    padding-left: 150px;
  }
}
main .dashboard .add-modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* background-color: #5b6770; */
  background-color: rgba(255, 255, 255, 0.5); /* fallback background */
  -webkit-backdrop-filter: blur(5px); /* fallback for older versions of Safari */
  backdrop-filter: blur(5px);
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  padding: 0 20px;
}

main .dashboard .add-modal #custom{
  align-items: center;
}

main .dashboard .add-modal.active {
  transition: 0.3s;
  visibility: visible;
  opacity: 1;
  z-index: 9;
}
main .dashboard .add-modal.content-admin {
  overflow: scroll;
}
main .dashboard .add-modal .select-add-organisation {
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.144);
  border-radius: 10px;
  width: 100%;
  max-width: 420px;
  position: relative;
}
main .dashboard .add-modal .select-add-organisation h3 {
  font-size: 1rem;
  margin-bottom: 15px;
  margin-top: 10px;
}
main .dashboard .add-modal .select-add-organisation .org-options,
main .dashboard .add-modal .select-add-organisation .user-options {
  margin-bottom: 10px;
}
main .dashboard .add-modal .select-add-organisation .org-options input,
main .dashboard .add-modal .select-add-organisation .user-options input {
  margin-bottom: 10px;
}
main .dashboard .add-modal .select-add-organisation .org-options input:nth-last-child(1),
main .dashboard .add-modal .select-add-organisation .user-options input:nth-last-child(1) {
  margin-bottom: 0;
}
main .dashboard .add-modal .select-add-organisation .org-options select.existing-org,
main .dashboard .add-modal .select-add-organisation .user-options select.existing-org {
  margin-bottom: 10px;
}
main .dashboard .add-modal .select-add-organisation span.next,
main .dashboard .add-modal .select-add-organisation span.prev {
  cursor: pointer;
  color: #1E75B0;
  display: block;
  margin-top: 10px;
  font-weight: 500;
  margin-right: 20px;
}
main .dashboard .add-modal .select-add-organisation span.next img,
main .dashboard .add-modal .select-add-organisation span.prev img {
  margin-bottom: 3px;
  margin-left: 5px;
  width: 12px;
}
main .dashboard .add-modal .select-add-organisation span.prev img {
  transform: rotate(180deg);
  margin-left: 0;
  margin-right: 5px;
}
main .dashboard .add-modal .select-add-organisation img.close-modal {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
main .dashboard .add-modal .select-add-organisation .add-options {
  margin-top: 20px;
}
main .dashboard .add-modal .select-add-organisation .add-options button.primary-btn {
  margin-right: 15px;
}
main .dashboard .add-modal .select-add-organisation .add-options button {
  font-size: 0.9rem;
  width: calc(50% - 10px);
}
main .dashboard .add-modal .select-add-organisation .next-prev-btns {
  margin-top: 20px;
  border-top: 1px solid #eee;
}
main .dashboard .add-modal .user-group,
main .dashboard .add-modal .select-add-organisation {
  transition: 0.3s;
}
main .dashboard .add-modal .user-group .user-tab,
main .dashboard .add-modal .user-group .org-tab,
main .dashboard .add-modal .select-add-organisation .user-tab,
main .dashboard .add-modal .select-add-organisation .org-tab {
  margin-top: 10px;
  margin-bottom: 10px;
}
main .dashboard .add-modal .user-group .user-tab .tab,
main .dashboard .add-modal .user-group .org-tab .tab,
main .dashboard .add-modal .select-add-organisation .user-tab .tab,
main .dashboard .add-modal .select-add-organisation .org-tab .tab {
  width: calc(50% - 10px);
  background-color: #E3EEF5;
  border-radius: 4px;
  transition: 0.3s;
  cursor: pointer;
}
main .dashboard .add-modal .user-group .user-tab .tab h3,
main .dashboard .add-modal .user-group .org-tab .tab h3,
main .dashboard .add-modal .select-add-organisation .user-tab .tab h3,
main .dashboard .add-modal .select-add-organisation .org-tab .tab h3 {
  margin-bottom: 10px;
  padding: 5px 0;
  text-align: center;
  font-weight: 500;
  font-size: 0.9rem;
  text-transform: uppercase;
}
main .dashboard .add-modal .user-group .user-tab .tab:hover, main .dashboard .add-modal .user-group .user-tab .tab.active,
main .dashboard .add-modal .user-group .org-tab .tab:hover,
main .dashboard .add-modal .user-group .org-tab .tab.active,
main .dashboard .add-modal .select-add-organisation .user-tab .tab:hover,
main .dashboard .add-modal .select-add-organisation .user-tab .tab.active,
main .dashboard .add-modal .select-add-organisation .org-tab .tab:hover,
main .dashboard .add-modal .select-add-organisation .org-tab .tab.active {
  transition: 0.3;
  background-color: #9F3037;
}
main .dashboard .add-modal .user-group .user-tab .tab:hover h3, main .dashboard .add-modal .user-group .user-tab .tab.active h3,
main .dashboard .add-modal .user-group .org-tab .tab:hover h3,
main .dashboard .add-modal .user-group .org-tab .tab.active h3,
main .dashboard .add-modal .select-add-organisation .user-tab .tab:hover h3,
main .dashboard .add-modal .select-add-organisation .user-tab .tab.active h3,
main .dashboard .add-modal .select-add-organisation .org-tab .tab:hover h3,
main .dashboard .add-modal .select-add-organisation .org-tab .tab.active h3 {
  color: #fff;
}
main .dashboard .add-modal .user-group .user-tab .tab.org,
main .dashboard .add-modal .user-group .org-tab .tab.org,
main .dashboard .add-modal .select-add-organisation .user-tab .tab.org,
main .dashboard .add-modal .select-add-organisation .org-tab .tab.org {
  width: calc(33% - 10px);
}
main .dashboard .add-modal .user-group .user-tab,
main .dashboard .add-modal .select-add-organisation .user-tab {
  margin-bottom: 20px;
}
main .dashboard .add-modal .user-group .tab-content,
main .dashboard .add-modal .select-add-organisation .tab-content {
  display: none;
}
main .dashboard .add-modal .user-group .tab-content.active,
main .dashboard .add-modal .select-add-organisation .tab-content.active {
  display: block;
}
main .dashboard .add-modal .user-group .drop_box,
main .dashboard .add-modal .select-add-organisation .drop_box {
  margin: 20px 0;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px dotted #E3EEF5;
  border-radius: 5px;
}
main .dashboard .add-modal .user-group .drop_box h4,
main .dashboard .add-modal .select-add-organisation .drop_box h4 {
  font-size: 0.9rem;
  font-weight: 400;
  color: #313E47;
}
main .dashboard .add-modal .user-group .form input,
main .dashboard .add-modal .select-add-organisation .form input {
  margin: 10px 0;
  width: 100%;
  background-color: #e2e2e2;
  border: none;
  outline: none;
  padding: 12px 20px;
  border-radius: 4px;
}
main .dashboard .add-modal.bulkActive {
  align-items: flex-start;
  overflow: scroll;
}
main .dashboard .add-modal.bulkActive .preview-users {
  margin-top: 100px;
  padding-bottom: 60px;
}
@media (min-width: 992px) {
  main .dashboard .add-modal.bulkActive .preview-users {
    margin-top: 180px;
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  main .dashboard .add-modal.bulkActive .preview-users {
    max-width: 1000px;
  }
}
main .dashboard .add-modal.bulkActive .preview-users .backlay {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
}
main .dashboard .add-modal.content-admin .select-content {
  transition: 0.3s;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.144);
  border-radius: 10px;
  width: 100%;
  max-width: 420px;
  position: relative;
}
main .dashboard .add-modal.content-admin .select-content h3 {
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
  margin-top: 10px;
}
main .dashboard .add-modal.content-admin .select-content img.close-modal {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
main .dashboard .add-modal.content-admin .select-content .input-wrapper .content-types .dropdown-trigger {
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  padding: 8px 15px;
  font-size: 0.9rem;
  margin-bottom: 10px;
}
main .dashboard .add-modal.content-admin .select-content .input-wrapper .content-types .dropdown-trigger img {
  transition: 0.3s;
}
main .dashboard .add-modal.content-admin .select-content .input-wrapper .content-types .dropdown-trigger.active-drop-down img {
  transform: rotate(-180deg);
  transition: 0.3s;
}
main .dashboard .add-modal.content-admin .select-content .input-wrapper .content-types .dropdown-content {
  background-color: #fff;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  height: 0;
  transform: translateX(-50px);
  transition: 0.2s;
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 40px;
  padding: 10px;
  border-radius: 10px;
}
main .dashboard .add-modal.content-admin .select-content .input-wrapper .content-types .dropdown-content .type {
  padding: 15px 10px;
  font-size: 0.9rem;
  background-color: transparent;
  border-radius: 10px;
  color: #313E47;
  font-weight: 400;
}
main .dashboard .add-modal.content-admin .select-content .input-wrapper .content-types .dropdown-content .type:nth-last-child(1) {
  margin-bottom: 0;
}
main .dashboard .add-modal.content-admin .select-content .input-wrapper .content-types .dropdown-content .type:hover {
  background-color: #f8f8f8;
  cursor: pointer;
}
main .dashboard .add-modal.content-admin .select-content .input-wrapper .content-types .dropdown-content.active-drop-down {
  transition: 0.5s;
  opacity: 1;
  visibility: visible;
  height: fit-content;
  transform: translateX(0);
}
main .dashboard .add-modal.content-admin .select-content .btn-wrapper {
  margin-top: 20px;
}
main .dashboard .add-modal.content-admin .select-content .btn-wrapper button.primary-btn {
  margin-right: 15px;
}
main .dashboard .add-modal.content-admin .select-content .btn-wrapper button {
  font-size: 0.9rem;
  width: calc(50% - 10px);
}
main .dashboard .add-modal.content-admin .select-content .next-prev-btns {
  margin-top: 20px;
}
main .dashboard .add-modal.content-admin .select-content span.next,
main .dashboard .add-modal.content-admin .select-content span.prev {
  cursor: pointer;
  color: #1E75B0;
  display: block;
  margin-top: 10px;
  font-weight: 500;
  margin-right: 20px;
}
main .dashboard .add-modal.content-admin .select-content span.next img,
main .dashboard .add-modal.content-admin .select-content span.prev img {
  margin-bottom: 3px;
  margin-left: 5px;
  width: 12px;
}
main .dashboard .add-modal.content-admin .select-content span.continue-link {
  position: absolute;
  bottom: 18px;
  left: 35px;
  font-size: 0.6rem;
  cursor: pointer;
  color: #9F3037;
  transition: 0.3s;
}
main .dashboard .add-modal.content-admin .select-content span.continue-link:hover {
  transition: 0.4s;
  color: #313E47;
}
main .dashboard .add-modal .tab-details {
  width: calc(100% - 40px);
  max-width: 1000px;
  background-color: #fff;
  overflow: scroll;
  position: absolute;
  top: 90px;
  bottom: 80px;
  border-radius: 10px;
  border-bottom: 15px solid #fff;
}
@media (min-width: 992px) {
  main .dashboard .add-modal .tab-details {
    top: 20%;
  }
}
@media (min-width: 992px) and (min-width: 1400px) {
  main .dashboard .add-modal .tab-details {
    top: 20%;
    max-width: 1200px;
    max-height: 700px;
  }
}
main .dashboard .add-modal .tab-details .inner-details {
  padding: 20px;
  margin: 15px 15px 0 15px;
  box-sizing: border-box;
}
main .dashboard .add-modal .tab-details .inner-details h6 {
  font-size: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
main .dashboard .add-modal .tab-details .inner-details .inner-input-wrapper .input-wrapper.half {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  main .dashboard .add-modal .tab-details .inner-details .inner-input-wrapper .input-wrapper.half {
    width: calc(50% - 10px);
  }
}
main .dashboard .add-modal .tab-details .inner-details .inner-input-wrapper .checkbox input {
  width: 20px;
  height: 20px;
}
main .dashboard .add-modal .tab-details .inner-details .disclaimer {
  margin-top: 40px;
}
main .dashboard .add-modal .tab-details .inner-details .settings {
  margin-top: 40px;
  margin-bottom: 0;
}
@media (min-width: 992px) {
  main .dashboard .add-modal .tab-details .inner-details .settings {
    margin-top: 0;
  }
}
main .dashboard .add-modal .tab-details .inner-details .settings .input-wrapper {
  margin-bottom: 10px;
}
main .dashboard .add-modal .tab-details .inner-details .permissions .input-wrapper {
  position: relative;
}
main .dashboard .add-modal .tab-details .inner-details .permissions .input-wrapper .search-btn {
  position: absolute;
  right: 10px;
  top: 8px;
  height: 20px;
  width: auto;
  opacity: 0.5;
}
main .dashboard .add-modal .tab-details .inner-details .permissions .input-wrapper input::placeholder {
  color: #CBCBCB;
}
main .dashboard .add-modal .tab-details .inner-details .permissions .input-wrapper label {
  width: calc(100% - 40px);
  margin-bottom: 15px;
}
main .dashboard .add-modal .tab-details .inner-details .permissions .seperator.alternative {
  margin: 20px 0;
  position: relative;
}
main .dashboard .add-modal .tab-details .inner-details .permissions .seperator.alternative .line {
  width: 100%;
  height: 1px;
  background-color: #E3EEF5;
  border-radius: 15px;
  position: absolute;
  left: 0;
  top: 13px;
  z-index: 0;
}
main .dashboard .add-modal .tab-details .inner-details .permissions .seperator.alternative p.alternative {
  text-align: center;
  background-color: #fff;
  padding: 5px 10px;
  position: relative;
  width: max-content;
  margin: auto;
  z-index: 999;
  font-size: 0.8rem;
}
main .dashboard .add-modal .tab-details .inner-details .permissions .inner-input-wrapper.third {
  padding: 20px;
  background-color: #e3eef531;
  border-radius: 10px;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  main .dashboard .add-modal .tab-details .inner-details .permissions .inner-input-wrapper.third {
    margin-bottom: 0;
  }
}
main .dashboard .add-modal .tab-details .inner-details .permissions .inner-input-wrapper.third h6 {
  border-bottom: 0;
  margin-bottom: 0;
}
main .dashboard .add-modal .tab-details .inner-details .selectedOrgs ul li.org {
  width: fit-content;
  border: 1px solid #eee;
  background-color: #E3EEF5;
  color: #313E47;
  border-radius: 10px;
  padding: 6px 12px;
  margin-left: 10px;
  font-size: 0.8rem;
  transition: 0.5s;
}
main .dashboard .add-modal .tab-details .header {
  border-bottom: 1px solid #eee;
  padding: 15px;
  position: sticky;
  top: 0px;
  background-color: #fff;
}
@media (min-width: 768px) {
  main .dashboard .add-modal .tab-details .header {
    padding: 20px;
  }
}
main .dashboard .add-modal .tab-details .header .tabs {
  width: 100%;
}
@media (min-width: 650px) {
  main .dashboard .add-modal .tab-details .header .tabs {
    width: calc(70% - 15px);
    justify-content: flex-start;
  }
}
main .dashboard .add-modal .tab-details .header .tabs .tab {
  padding: 2px 0;
  margin-right: 10px;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: none;
  border-bottom: 2px solid transparent;
  transition: 0.3s;
  cursor: pointer;
  color: #313E47;
}
@media (min-width: 768px) {
  main .dashboard .add-modal .tab-details .header .tabs .tab {
    margin-right: 20px;
  }
}
main .dashboard .add-modal .tab-details .header .tabs .tab:nth-last-child(1) {
  margin-right: 0;
}
main .dashboard .add-modal .tab-details .header .tabs .tab:hover, main .dashboard .add-modal .tab-details .header .tabs .tab.active {
  border-color: #9F3037;
  transition: 0.3s;
  color: #9F3037;
}
main .dashboard .add-modal .tab-details .header .tabs .tab.active {
  color: #9F3037;
}
main .dashboard .add-modal .tab-details .header .btn-wrapper {
  width: 100%;
  margin-top: 10px;
}
@media (min-width: 650px) {
  main .dashboard .add-modal .tab-details .header .btn-wrapper {
    width: calc(30% - 15px);
    margin-top: 0;
  }
}
main .dashboard .add-modal .tab-details .header .btn-wrapper .btn {
  width: calc(50% - 5px);
  margin-right: 10px;
}
main .dashboard .add-modal .tab-details .header .btn-wrapper .btn:nth-last-child(1) {
  margin-right: 0;
}
@media (min-width: 650px) {
  main .dashboard .add-modal .tab-details .header .btn-wrapper .btn {
    width: auto;
    padding: 6px 10px;
    margin-right: 20px;
  }
}

@media (min-width: 1400px) {
  main.organisations {
    /* margin-top: 150px; */
  }
}
main.organisations .box-info {
  background-color: transparent;
}

.edit-modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
 /*  background-color: #5b6770; */
 background-color: rgba(255, 255, 255, 0.5); /* fallback background */
 -webkit-backdrop-filter: blur(5px); /* fallback for older versions of Safari */
 backdrop-filter: blur(5px);
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  padding: 0 20px;
}
.edit-modal.active {
  transition: 0.3s;
  visibility: visible;
  opacity: 1;
  z-index: 9;
}
.edit-modal .confirmation-box {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
}
.edit-modal .confirmation-box h6 {
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 25px;
}
.edit-modal .confirmation-box .btn-wrapper .btn {
  width: 100%;
  margin-bottom: 10px;
}
.edit-modal .confirmation-box .btn-wrapper .btn:nth-last-child(1) {
  margin-bottom: 0;
}
@media (min-width: 576px) {
  .edit-modal .confirmation-box .btn-wrapper .btn {
    width: calc(50% - 10px);
    font-size: 0.9rem;
    margin-bottom: 0;
  }
}
.edit-modal .view-edit-profile {
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.144);
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  position: relative;
}
.edit-modal .view-edit-profile .user-info-wrapper {
  padding: 10px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #f9f9f9;
  position: relative;
}
.edit-modal .view-edit-profile .user-info-wrapper .user-icon {
  width: 40px;
  margin-right: 5px;
}
.edit-modal .view-edit-profile .user-info-wrapper .user-info {
  padding: 10px;
}
.edit-modal .view-edit-profile .user-info-wrapper .user-info h4 {
  font-size: 1rem;
  margin-bottom: -3px;
}
.edit-modal .view-edit-profile .user-info-wrapper .user-info span.email {
  font-weight: 300;
  font-size: 0.8rem;
}
.edit-modal .view-edit-profile .user-info-wrapper img.active,
.edit-modal .view-edit-profile .user-info-wrapper img.inactive {
  display: none;
  position: absolute;
  right: 20px;
  top: 30px;
}
.edit-modal .view-edit-profile .user-info-wrapper.active img.active {
  display: block;
}
.edit-modal .view-edit-profile .user-info-wrapper.inactive img.inactive {
  display: block;
}
.edit-modal .view-edit-profile .editable-info {
  padding: 5px 20px 5px 20px;
}
.edit-modal .view-edit-profile .editable-info .organisation h4,
.edit-modal .view-edit-profile .editable-info .personal h4,
.edit-modal .view-edit-profile .editable-info .permissions h4 {
  margin-bottom: 10px;
  margin-top: 20px;
  display: block;
  font-size: 1rem;
  width: 100%;
}
.edit-modal .view-edit-profile .editable-info .personal input {
  margin-bottom: 10px;
}
.edit-modal .view-edit-profile .btn-wrapper {
  padding: 10px 20px 20px 20px;
}
.edit-modal .view-edit-profile .btn-wrapper button {
  margin-left: 20px;
}

main.modules .tab-header {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid #eee;
}
@media (min-width: 576px) {
  main.modules .tab-header h4 {
    margin-bottom: 0;
  }
}
main.modules .tab-header .tab-wrapper {
  width: 100%;
}
@media (min-width: 576px) {
  main.modules .tab-header .tab-wrapper {
    width: auto;
  }
}
main.modules .tab-header .tab-wrapper .btn {
  width: calc(50% - 10px);
  display: flex;
  align-items: center;
  font-weight: 500;
}
@media (min-width: 576px) {
  main.modules .tab-header .tab-wrapper .btn {
    width: auto;
    margin-left: 20px;
  }
}
main.modules .tab-header .tab-wrapper .btn .icon {
  transition: 0.3s;
  width: 12px;
  margin-left: 10px;
}
main.modules .tab-header .tab-wrapper .btn:hover .icon {
  transition: 0.3s;
  filter: invert(1);
}
main.modules .tab-content .roles {
  margin-bottom: 40px;
}
main.modules .tab-content .roles .add-roles {
  margin-bottom: 30px;
}
main.modules .tab-content .roles .add-roles input {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  main.modules .tab-content .roles .add-roles input {
    margin-bottom: 0;
    width: calc(75% - 5px);
  }
}
main.modules .tab-content .roles .add-roles button {
  width: 100%;
}
@media (min-width: 768px) {
  main.modules .tab-content .roles .add-roles button {
    width: calc(25% - 5px);
  }
}
main.modules .tab-content .roles .role-list {
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 10px;
}
main.modules .tab-content .roles .role-list ul li {
  padding: 10px 15px;
  margin-bottom: 5px;
  background-color: #f8f8f8;
  border-radius: 4px;
  font-size: 0.8rem;
  color: #313E47;
}
main.modules .tab-content .roles .role-list ul li:nth-last-child(1) {
  margin-bottom: 0;
}
main.modules .tab-content .roles .role-list ul li img.icon {
  width: 15px;
  margin-left: 10px;
  cursor: pointer;
  opacity: 0.6;
  transition: 0.4;
}
main.modules .tab-content .roles .role-list ul li img.icon:hover {
  transition: 0.4s;
  opacity: 1;
}
@media (min-width: 1200px) {
  main.modules .tab-content .roles .role-list h6 {
    font-size: 20px;
  }
}

section.hero-banner {
  padding-top: 85px;
  height: 80vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media (min-width: 992px) {
  section.hero-banner {
    /* background-position-y: 100px; */
  }
}
@media (min-width: 992px) and (min-width: 1400px) {
  section.hero-banner {
    height: 100vh;
    /* background-position-y: 50px; */
  }
}
section.hero-banner .hero-content h1 {
  font-weight: 900;
}

header.header_basic {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #313e471f;
  z-index: 99999;
}
header.header_basic.mobile_active {
  border-color: #fff;
}
header.header_basic nav {
  width: 100%;
}
@media (min-width: 992px) {
  header.header_basic nav {
    margin-top: 65px;
  }
}
@media (min-width: 992px) and (min-width: 1400px) {
  header.header_basic nav {
    margin-top: 0;
  }
}
header.header_basic nav .subscriber-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  top: 65px;
  left: 0;
  width: 100%;
  padding: 0 20px 20px 20px;
  border-top: 1px solid #f7f7f7;
}
@media (min-width: 992px) {
  header.header_basic nav .subscriber-menu {
    width: calc(80% - 10px);
    position: relative;
    top: unset;
    left: unset;
    border: none;
    display: block;
    padding: 0;
  }
}
@media (min-width: 992px) and (min-width: 1400px) {
  header.header_basic nav .subscriber-menu {
    width: 85%;
    display: flex;
    justify-content: flex-end;
  }
}
header.header_basic nav .subscriber-menu ul.menu {
  padding-bottom: 20px;
}
@media (min-width: 992px) {
  header.header_basic nav .subscriber-menu ul.menu {
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
@media (min-width: 992px) and (min-width: 1400px) {
  header.header_basic nav .subscriber-menu ul.menu {

    width: calc(95% - 10px);


    
    
    
    
  }
}
header.header_basic nav .subscriber-menu ul.menu li {
  margin: 3px 0;
}
@media (min-width: 992px) {
  header.header_basic nav .subscriber-menu ul.menu li {
    margin: 0;
  }
}
header.header_basic nav .subscriber-menu ul.menu li a {
  padding: 10px;
  text-transform: uppercase;
  display: block;
  background-color: #f7f7f7;
  text-align: center;
  transition: 0.3s;
  border-bottom: 2px solid transparent;
}
@media (min-width: 992px) {
  header.header_basic nav .subscriber-menu ul.menu li a {
    background-color: transparent;
    padding: 0;
    margin: 10px 0 10px 20px;
  }
}
header.header_basic nav .subscriber-menu ul.menu li a:hover {
  transition: 0.3s;
  border-bottom: 2px solid #9F3037;
}
header.header_basic nav .subscriber-menu ul.menu li a.router-link-active.active {
  transition: 0.3s;
  font-weight: 600;
  border-bottom: 2px solid #9F3037;
}
@media (min-width: 992px) {
  header.header_basic nav .subscriber-menu .user-actions {
    position: fixed;
    right: 20px;
    top: 20px;
    width: calc(100% - 40px);
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  header.header_basic nav .subscriber-menu .user-actions {
    width: calc(70% - 40px);
  }
}
@media (min-width: 992px) and (min-width: 1200px) and (min-width: 1400px) {
  header.header_basic nav .subscriber-menu .user-actions {
    position: relative;
    right: unset;
    top: unset;
    width: calc(60% - 10px);
  }
}
header.header_basic nav .subscriber-menu .user-actions .button-wrapper {
  width: 100%;
}
@media (min-width: 992px) {
  header.header_basic nav .subscriber-menu .user-actions .button-wrapper {
    width: calc(40% - 10px);
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  header.header_basic nav .subscriber-menu .user-actions .button-wrapper {
    justify-content: flex-end;
  }
}
header.header_basic nav .subscriber-menu .user-actions .button-wrapper button {
  width: calc(50% - 10px);
}
@media (min-width: 1200px) {
  header.header_basic nav .subscriber-menu .user-actions .button-wrapper button {
    width: auto;
    margin-left: 20px;
    font-size: 0.9rem;
  }
}
header.header_basic nav .subscriber-menu .user-actions .search-login {
  width: 100%;
  margin-top: 20px;
}
@media (min-width: 992px) {
  header.header_basic nav .subscriber-menu .user-actions .search-login {
    width: calc(60% - 10px);
    margin-top: 0;
  }
}
header.header_basic nav .subscriber-menu .user-actions .search-login form {
  position: relative;
}
header.header_basic nav .subscriber-menu .user-actions .search-login form input {
  width: calc(70% - 10px);
  border: 1px solid #313E47;
}
header.header_basic nav .subscriber-menu .user-actions .search-login form input::placeholder {
  color: #313E47;
}
@media (min-width: 1200px) {
  header.header_basic nav .subscriber-menu .user-actions .search-login form input {
    width: calc(75% - 10px);
  }
}
header.header_basic nav .subscriber-menu .user-actions .search-login form a.btn {
  width: calc(30% - 10px);
}
@media (min-width: 1200px) {
  header.header_basic nav .subscriber-menu .user-actions .search-login form a.btn {
    font-size: 0.9rem;
    width: calc(25% - 10px);
  }
}
header.header_basic nav .subscriber-menu .user-actions .search-login form button.icon {
  position: absolute;
  border: none;
  top: 8px;
  left: calc(70% - 45px);
  background-color: transparent;
}
@media (min-width: 1200px) {
  header.header_basic nav .subscriber-menu .user-actions .search-login form button.icon {
    left: calc(75% - 45px);
  }
}
header.header_basic nav .subscriber-menu .user-actions .search-login form .hamburger-menu {
  position: relative;
}
header.header_basic nav .subscriber-menu .user-actions .search-login form .hamburger-menu .menu-options {
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  position: absolute;
  z-index: 999;
  background-color: #fff;
  top: 40px;
  right: 0;
  box-shadow: 2px 2px 10px #00000030;
  border-radius: 4px;
  width: 200px;
}
header.header_basic nav .subscriber-menu .user-actions .search-login form .hamburger-menu .menu-options a.link {
  color: #282b2d;
  margin: 15px 20px;
  display: block;
  transition: 0.2s;
}
header.header_basic nav .subscriber-menu .user-actions .search-login form .hamburger-menu .menu-options a.link:hover {
  transition: 0.3s;
  color: #9F3037;
}
header.header_basic nav .subscriber-menu .user-actions .search-login form .hamburger-menu:hover .menu-options {
  opacity: 1;
  visibility: visible;
  transition: 0.3s;
}
@media (min-width: 1200px) {
  header.header_basic nav .subscriber-menu .user-actions.admin {
    position: relative;
    right: unset;
    top: unset;
    width: calc(60% - 10px);
  }
}
header.header_basic nav .subscriber-menu .user-actions.admin .search-login {
  margin-top: 0;
}
@media (min-width: 992px) {
  header.header_basic nav .subscriber-menu .user-actions.admin .search-login {
    width: calc(40% - 10px);
  }
}
header.header_basic nav .subscriber-menu .user-actions.admin .search-login form input {
  width: calc(100% - 40px);
}
header.header_basic nav .subscriber-menu .user-actions.admin .search-login form button.icon {
  left: calc(100% - 80px);
}
@media (min-width: 1200px) {
  header.header_basic nav .subscriber-menu.admin {
    width: calc(100% - 240px);
    display: flex;
    justify-content: space-between;
  }
  header.header_basic nav .subscriber-menu.admin .menu {
    width: calc(70% - 10px);
    justify-content: flex-start;
  }
  header.header_basic nav .subscriber-menu.admin .user-actions {
    width: calc(30% - 10px);
  }
  header.header_basic nav .subscriber-menu.admin .user-actions .search-login {
    width: 100%;
  }
}
header.header_basic nav .mobile-toggle {
  position: absolute;
  top: 25px;
  right: 20px;
}
@media (min-width: 992px) {
  header.header_basic nav .mobile-toggle {
    display: none;
  }
}
header.header_basic nav .mobile-toggle img {
  display: block;
  cursor: pointer;
  transition: 0.1s;
  position: absolute;
  top: 0;
  right: 0;
}
header.header_basic nav .mobile-toggle img.close {
  opacity: 0;
  visibility: hidden;
}
header.header_basic nav .mobile-toggle.mobile_active img.close {
  transition: 0.2s;
  opacity: 1;
  visibility: visible;
}
header.header_basic nav .mobile-toggle.mobile_active img.open {
  transition: 0.2s;
  opacity: 0;
  visibility: hidden;
}
header.header_basic nav.mobile_active .subscriber-menu {
  display: block;
}
@media (min-width: 1200px) {
  header.header_basic nav.admin {
    margin-top: 0;
  }
}

nav.sidenav {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #fff;
  border-top: 1px solid #313e471f;
  padding: 10px 20px;
  width: 100%;
  z-index: 9999;
}
@media (min-width: 1200px) {
  nav.sidenav {
    width: auto;
    height: calc(100% - 85px);
    display: flex;
    justify-content: center;
    border-top: none;
    border-right: 1px solid #313e471f;
    padding: 20px 10px;
  }
  nav.sidenav .menu {
    flex-direction: column;
    margin: auto 0;
  }
}
nav.sidenav a.menu-link {
  background-color: transparent;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  transition: 0.3s;
}
@media (min-width: 1200px) {
  nav.sidenav a.menu-link {
    margin: 10px 0;
  }
}
nav.sidenav a.menu-link img {
  width: 22px;
}
nav.sidenav a.menu-link:hover, nav.sidenav a.menu-link.router-link-active.active {
  transition: 0.3s;
  background-color: #313E47;
}

footer.footer {
  margin-top: 400px;
  border-top: 3px solid #313E47;
  padding: 20px 0;
}
@media (min-width: 1200px) {
  footer.footer {
    padding: 40px 0;
  }
}
@media (min-width: 1200px) and (min-width: 1400px) {
  footer.footer {
    padding: 60px 0;
  }
}
footer.footer img.logo {
  display: block;
  margin: 0 auto;
}
@media (min-width: 992px) {
  footer.footer img.logo {
    margin: initial;
    width: 220px;
  }
}
footer.footer .quick-links {
  margin: 40px 0;
}
@media (min-width: 992px) {
  footer.footer .quick-links {
    margin: 0;
  }
}
footer.footer .quick-links a {
  display: block;
  color: #313E47;
  font-weight: 500;
  text-transform: uppercase;
  margin: 5px 0;
  text-align: center;
}
@media (min-width: 992px) {
  footer.footer .quick-links a {
    text-align: left;
    margin: 0;
    margin-bottom: 20px;
  }
}
footer.footer .half p {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  footer.footer .half p {
    text-align: left;
  }
}
@media (min-width: 992px) {
  footer.footer .half .social-links {
    justify-content: flex-start;
  }
}
footer.footer .half .social-links a {
  display: inline-block;
  margin: 0 20px;
}
@media (min-width: 992px) {
  footer.footer .half .social-links a {
    margin: 0 20px 0 0;
  }
}
footer.footer .half .social-links a img {
  height: 20px;
}

@media (min-width: 992px) {
  .filter {
    flex-direction: column;
  }
}
.filter .filter-item {
  margin-right: 15px;
  margin-bottom: 5px;
  transition: 0.3s;
  cursor: pointer;
  padding: 5px 10px;
}
@media (min-width: 992px) {
  .filter .filter-item {
    padding: 7px 10px;
  }
}
.filter .filter-item.active, .filter .filter-item:hover {
  transition: 0.3s;
  background-color: #313E47;
  border-radius: 4px;
}
.filter .filter-item.active p, .filter .filter-item:hover p {
  color: #fff;
}

@media (min-width: 992px) {
  #filter-wrapper {
    align-items: flex-start;
  }
}

.line-chart {
  display: none;
}
.line-chart.active {
  display: block;
}
.line-chart.stats {
  height: 300px;
  padding-bottom: 60px;
}
.line-chart span.high-state {
  font-size: 2rem;
  font-weight: 800;
  color: #1E75B0;
}
.line-chart h6 {
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 5px;
  font-weight: 600;
}

@media (min-width: 1200px) {
  .users-list,
.organisation-list {
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
  }
}
.users-list .header,
.users-list .list-options,
.organisation-list .header,
.organisation-list .list-options {
  margin-bottom: 10px;
}
@media (min-width: 1200px) {
  .users-list .header,
.users-list .list-options,
.organisation-list .header,
.organisation-list .list-options {
    position: absolute;
    top: -10px;
    right: 0;
  }
}
.users-list .header button.btn,
.users-list .list-options button.btn,
.organisation-list .header button.btn,
.organisation-list .list-options button.btn {
  padding: 7px 12px;
  font-size: 0.7rem;
  margin-left: 10px;
}
@media (min-width: 1200px) {
  .users-list .header button.btn,
.users-list .list-options button.btn,
.organisation-list .header button.btn,
.organisation-list .list-options button.btn {
    padding: 5px 10px;
  }
}
.users-list .header button.btn.more img,
.users-list .list-options button.btn.more img,
.organisation-list .header button.btn.more img,
.organisation-list .list-options button.btn.more img {
  width: 14px;
  margin-left: 6px;
}
@media (min-width: 1200px) {
  .users-list .header button.btn.more img,
.users-list .list-options button.btn.more img,
.organisation-list .header button.btn.more img,
.organisation-list .list-options button.btn.more img {
    width: 12px;
  }
}
.users-list .header button.btn:hover,
.users-list .list-options button.btn:hover,
.organisation-list .header button.btn:hover,
.organisation-list .list-options button.btn:hover {
  transition: 0.3s;
  background-color: #313E47;
  color: #fff;
  border-color: #313E47;
}
.users-list .table .list-title,
.organisation-list .table .list-title {
  display: none;
}
@media (min-width: 1200px) {
  .users-list .table .list-title,
.organisation-list .table .list-title {
    display: flex;
    margin-bottom: 10px;
    margin-left: 20px;
  }
  .users-list .table .list-title span.title,
.organisation-list .table .list-title span.title {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #313E47;
    width: calc(20% - 5px);
  }
}
.users-list .table .list-wrap .list-item,
.organisation-list .table .list-wrap .list-item {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 6px #00000018;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  display: block;
}
@media (min-width: 768px) {
  .users-list .table .list-wrap .list-item,
.organisation-list .table .list-wrap .list-item {
    width: calc(50% - 10px);
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .users-list .table .list-wrap .list-item,
.organisation-list .table .list-wrap .list-item {
    width: 100%;
    display: flex;
    box-shadow: 0 2px 10px #0000000f;
    margin-bottom: 10px;
  }
}
.users-list .table .list-wrap .list-item span.item,
.organisation-list .table .list-wrap .list-item span.item {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  color: #313E47;
}
@media (min-width: 1200px) {
  .users-list .table .list-wrap .list-item span.item,
.organisation-list .table .list-wrap .list-item span.item {
    width: 20%;
    margin-bottom: 0;
    font-size: 0.8rem;
  }
}
@media (min-width: 1200px) and (min-width: 1400px) {
  .users-list .table .list-wrap .list-item span.item,
.organisation-list .table .list-wrap .list-item span.item {
    font-size: 0.9rem;
  }
}
.users-list .table .list-wrap .list-item span.item:nth-child(5),
.organisation-list .table .list-wrap .list-item span.item:nth-child(5) {
  margin-bottom: 0;
}
.users-list .table .list-wrap .list-item span.item .mobile-title,
.organisation-list .table .list-wrap .list-item span.item .mobile-title {
  display: block;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #313E47;
}
@media (min-width: 1200px) {
  .users-list .table .list-wrap .list-item span.item .mobile-title,
.organisation-list .table .list-wrap .list-item span.item .mobile-title {
    display: none;
  }
}
.users-list .table .list-wrap .list-item span.item.options,
.organisation-list .table .list-wrap .list-item span.item.options {
  position: absolute;
  top: 33px;
  right: 30px;
  width: auto;
  transition: 0.3s;
}
@media (min-width: 1200px) {
  .users-list .table .list-wrap .list-item span.item.options,
.organisation-list .table .list-wrap .list-item span.item.options {
    top: 20px;
    right: 20px;
  }
}
.users-list .table .list-wrap .list-item span.item.options .options-content,
.organisation-list .table .list-wrap .list-item span.item.options .options-content {
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 9999;
  width: 200px;
  border-radius: 4px;
  box-shadow: 0 2px 10px #00000024;
}
.users-list .table .list-wrap .list-item span.item.options .options-content ul.option-list,
.organisation-list .table .list-wrap .list-item span.item.options .options-content ul.option-list {
  border-bottom: 1px solid #eee;
}
.users-list .table .list-wrap .list-item span.item.options .options-content ul.option-list:nth-last-child(1),
.organisation-list .table .list-wrap .list-item span.item.options .options-content ul.option-list:nth-last-child(1) {
  border-bottom: none;
}
.users-list .table .list-wrap .list-item span.item.options .options-content ul.option-list li,
.organisation-list .table .list-wrap .list-item span.item.options .options-content ul.option-list li {
  padding: 10px 15px;
  background-color: transparent;
  border-radius: 4px;
  transition: 0.3s;
  color: #313E47;
  font-size: 0.8rem;
  cursor: pointer;
  margin: 5px;
}
.users-list .table .list-wrap .list-item span.item.options .options-content ul.option-list li:hover,
.organisation-list .table .list-wrap .list-item span.item.options .options-content ul.option-list li:hover {
  background-color: #eee;
}
.users-list .table .list-wrap .list-item span.item.options .option-toggle,
.organisation-list .table .list-wrap .list-item span.item.options .option-toggle {
  position: absolute;
  right: 0;
  cursor: pointer;
}
.users-list .table .list-wrap .list-item span.item.options.active .options-content,
.organisation-list .table .list-wrap .list-item span.item.options.active .options-content {
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
}
.users-list .table .list-wrap .list-item span.item.mobile-settings,
.organisation-list .table .list-wrap .list-item span.item.mobile-settings {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
@media (min-width: 1200px) {
  .users-list .table .list-wrap .list-item span.item.mobile-settings,
.organisation-list .table .list-wrap .list-item span.item.mobile-settings {
    padding-bottom: 0;
    border-bottom: 0;
  }
}
.users-list .table .list-wrap .list-item span.item.mobile-settings span.user,
.organisation-list .table .list-wrap .list-item span.item.mobile-settings span.user {
  padding: 10px 15px;
  background-color: #E3EEF5;
  border-radius: 4px;
  display: block;
  width: calc(100% - 55px);
}
@media (min-width: 1200px) {
  .users-list .table .list-wrap .list-item span.item.mobile-settings span.user,
.organisation-list .table .list-wrap .list-item span.item.mobile-settings span.user {
    padding: 0;
    background-color: transparent;
    border-radius: 0;
    width: calc(100% - 45px);
  }
}
.users-list .table .list-wrap .list-item span.item.mobile-settings span.user span,
.organisation-list .table .list-wrap .list-item span.item.mobile-settings span.user span {
  display: block;
  line-height: 1.2;
}
.users-list .table .list-wrap .list-item span.item.mobile-settings span.user span.fullname,
.users-list .table .list-wrap .list-item span.item.mobile-settings span.user span a,
.organisation-list .table .list-wrap .list-item span.item.mobile-settings span.user span.fullname,
.organisation-list .table .list-wrap .list-item span.item.mobile-settings span.user span a {
  font-size: 1rem;
  font-weight: 500;
  color: #313E47;
  cursor: pointer;
}
@media (min-width: 1200px) {
  .users-list .table .list-wrap .list-item span.item.mobile-settings span.user span.fullname,
.users-list .table .list-wrap .list-item span.item.mobile-settings span.user span a,
.organisation-list .table .list-wrap .list-item span.item.mobile-settings span.user span.fullname,
.organisation-list .table .list-wrap .list-item span.item.mobile-settings span.user span a {
    font-size: 0.8rem;
  }
}
@media (min-width: 1200px) and (min-width: 1400px) {
  .users-list .table .list-wrap .list-item span.item.mobile-settings span.user span.fullname,
.users-list .table .list-wrap .list-item span.item.mobile-settings span.user span a,
.organisation-list .table .list-wrap .list-item span.item.mobile-settings span.user span.fullname,
.organisation-list .table .list-wrap .list-item span.item.mobile-settings span.user span a {
    font-size: 0.9rem;
  }
}
.users-list .table .list-wrap .list-item span.item.mobile-settings span.user span.email a,
.organisation-list .table .list-wrap .list-item span.item.mobile-settings span.user span.email a {
  color: #9F3037;
  font-size: 0.8rem;
  transition: 0.3;
  border-bottom: 1px solid transparent;
  font-weight: 300;
}
@media (min-width: 1200px) {
  .users-list .table .list-wrap .list-item span.item.mobile-settings span.user span.email a,
.organisation-list .table .list-wrap .list-item span.item.mobile-settings span.user span.email a {
    font-size: 0.7rem;
  }
}
.users-list .table .list-wrap .list-item span.item.mobile-settings span.user span.email a:hover,
.organisation-list .table .list-wrap .list-item span.item.mobile-settings span.user span.email a:hover {
  transition: 0.3s;
  border-color: #9F3037;
}
.users-list .table .list-wrap .list-item span.item.mobile-settings img.user-icon,
.organisation-list .table .list-wrap .list-item span.item.mobile-settings img.user-icon {
  width: 40px;
}
@media (min-width: 1200px) {
  .users-list .table .list-wrap .list-item span.item.mobile-settings img.user-icon,
.organisation-list .table .list-wrap .list-item span.item.mobile-settings img.user-icon {
    width: 30px;
  }
}
.users-list .table .list-wrap .list-item .status,
.organisation-list .table .list-wrap .list-item .status {
  position: absolute;
  right: 60px;
  top: 34px;
}
@media (min-width: 1200px) {
  .users-list .table .list-wrap .list-item .status,
.organisation-list .table .list-wrap .list-item .status {
    right: 55px;
    top: 23px;
  }
}
.users-list .table .list-wrap .list-item .status img,
.organisation-list .table .list-wrap .list-item .status img {
  width: 18px;
}

.bulkActive .users-list .table .list-wrap .list-item span.item.mobile-settings span.user-options {
  display: flex;
}
.bulkActive .users-list .table .list-wrap .list-item span.item.mobile-settings span.user-options span {
  margin-right: 5px;
  cursor: pointer;
}

.types,
.groups {
  background-color: #fff;
  padding: 20px 20px 0 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px #00000012;
  max-height: 370px;
}
.types .list .list-wrap,
.groups .list .list-wrap {
  overflow-y: scroll;
  border-bottom: 20px solid #fff;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  max-height: 320px;
  /* Firefox */
}
.types .list .list-wrap .list-item,
.groups .list .list-wrap .list-item {
  background-color: #d8827812;
  border-radius: 4px;
  margin-top: 5px;
  padding: 6px 18px;
  position: relative;
  transition: 0.4s;
  cursor: pointer;
}
.types .list .list-wrap .list-item .type-info span,
.groups .list .list-wrap .list-item .type-info span {
  display: block;
  line-height: 1.4;
}
.types .list .list-wrap .list-item .type-info .name,
.groups .list .list-wrap .list-item .type-info .name {
  display: block;
  color: #313E47;
  font-weight: 600;
}
.types .list .list-wrap .list-item .type-info .desc,
.groups .list .list-wrap .list-item .type-info .desc {
  font-size: 0.7rem;
}
.types .list .list-wrap .list-item:hover,
.groups .list .list-wrap .list-item:hover {
  transition: 0.2s;
  transform: translateX(5px);
}
.types .list .list-wrap::-webkit-scrollbar,
.groups .list .list-wrap::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
.types .list h6,
.groups .list h6 {
  font-size: 1rem;
  margin-bottom: 10px;
}

.groups .list-item {
  background-color: #1e76b028 !important;
}

.types .list-item::before {
  content: "";
  position: absolute;
  left: 3px;
  top: 3px;
  width: 3px;
  border-radius: 2px;
  height: calc(100% - 6px);
  background-color: #d88278;
}

.groups .list-item::before {
  content: "";
  position: absolute;
  left: 3px;
  top: 3px;
  width: 3px;
  border-radius: 2px;
  height: calc(100% - 6px);
  background-color: #1E75B0;
}

@media (min-width: 1200px) {
  .content-list {
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
  }
}
.content-list .header {
  border-bottom: 2px solid #313E47;
  margin-bottom: 20px;
  position: relative;
}
@media (min-width: 1200px) {
  .content-list .header {
    position: absolute;
    right: 0;
    top: -15px;
    border: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .content-list .header .filter {
    max-width: calc(50% - 10px);
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .content-list .header .filter {
    min-width: 150px;
  }
}
@media (min-width: 768px) and (min-width: 1200px) and (min-width: 1200px) {
  .content-list .header .filter {
    min-width: 200px;
  }
}
.content-list .header .filter .filter-btn {
  border: 1px solid #eee;
  padding: 14px 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  cursor: pointer;
}
@media (min-width: 1200px) {
  .content-list .header .filter .filter-btn {
    padding: 7px 15px;
  }
}
.content-list .header .filter .filter-btn span {
  font-size: 0.8rem;
  color: #282b2d;
}
.content-list .header .filter .filter-btn img {
  transition: 0.3s;
}
.content-list .header .filter .filter-btn.active img {
  transition: 0.4s;
  transform: rotate(180deg);
}
.content-list .header .filter .filter-list {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: -9;
  background-color: #fff;
  border-radius: 4px;
  transition: 0.4s;
  width: 100%;
  top: 55px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.139);
}
@media (min-width: 768px) {
  .content-list .header .filter .filter-list {
    max-width: calc(50% - 10px);
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .content-list .header .filter .filter-list {
    top: 45px;
    max-width: 100%;
  }
}
.content-list .header .filter .filter-list ul li {
  font-size: 0.8rem;
  padding: 8px 15px;
  transition: 0.3s;
  cursor: pointer;
  margin: 3px;
  border-radius: 4px;
  color: #282b2d;
}
.content-list .header .filter .filter-list ul li:hover {
  color: #9F3037;
  transition: 0.4s;
  background-color: #f7f7f7;
}
.content-list .header .filter .filter-list.active {
  transition: 0.4s;
  z-index: 9;
  opacity: 1;
  visibility: visible;
}
.content-list .table .list-title {
  display: none;
}
@media (min-width: 1200px) {
  .content-list .table .list-title {
    display: flex;
    margin-bottom: 10px;
    margin-left: 20px;
  }
  .content-list .table .list-title span.title {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #313E47;
    width: calc(22% - 5px);
  }
  .content-list .table .list-title span.title.type {
    width: 10%;
  }
}
.content-list .table .list-wrap .list-item {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #f8f8f8;
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  display: block;
}
@media (min-width: 768px) {
  .content-list .table .list-wrap .list-item {
    width: calc(50% - 10px);
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .content-list .table .list-wrap .list-item {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    padding: 25px 20px;
  }
}
.content-list .table .list-wrap .list-item span.item {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  color: #313E47;
}
@media (min-width: 1200px) {
  .content-list .table .list-wrap .list-item span.item {
    width: 22%;
    margin-bottom: 0;
    font-size: 0.8rem;
  }
}
@media (min-width: 1200px) and (min-width: 1400px) {
  .content-list .table .list-wrap .list-item span.item {
    font-size: 0.9rem;
  }
}
.content-list .table .list-wrap .list-item span.item.type {
  border-bottom: 1px solid #9f2f3733;
  margin-bottom: 15px;
  padding-bottom: 15px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
}
.content-list .table .list-wrap .list-item span.item.type img {
  margin-right: 10px;
}
@media (min-width: 1200px) {
  .content-list .table .list-wrap .list-item span.item.type img {
    margin-right: 0;
    margin-left: 40px;
  }
}
@media (min-width: 1200px) {
  .content-list .table .list-wrap .list-item span.item.type {
    margin-bottom: 0;
    padding-bottom: 0;
    border: 0;
    text-transform: capitalize;
    width: 10%;
  }
  .content-list .table .list-wrap .list-item span.item.type span {
    display: none;
  }
}
.content-list .table .list-wrap .list-item span.item:nth-child(5) {
  margin-bottom: 0;
}
.content-list .table .list-wrap .list-item span.item .mobile-title {
  display: block;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #313E47;
}
@media (min-width: 1200px) {
  .content-list .table .list-wrap .list-item span.item .mobile-title {
    display: none;
  }
}
.content-list .table .list-wrap .list-item span.item.options {
  position: absolute;
  top: 17px;
  right: 20px;
  width: auto;
  transition: 0.3s;
}
@media (min-width: 1200px) {
  .content-list .table .list-wrap .list-item span.item.options {
    top: 20px;
    right: 20px;
  }
}
.content-list .table .list-wrap .list-item span.item.options .options-content {
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 9999;
  width: 200px;
  border-radius: 4px;
  box-shadow: 0 2px 10px #00000024;
}
.content-list .table .list-wrap .list-item span.item.options .options-content ul.option-list {
  border-bottom: 1px solid #eee;
}
.content-list .table .list-wrap .list-item span.item.options .options-content ul.option-list:nth-last-child(1) {
  border-bottom: none;
}
.content-list .table .list-wrap .list-item span.item.options .options-content ul.option-list li {
  padding: 10px 15px;
  background-color: transparent;
  border-radius: 4px;
  transition: 0.3s;
  color: #313E47;
  font-size: 0.8rem;
  cursor: pointer;
  margin: 5px;
}
.content-list .table .list-wrap .list-item span.item.options .options-content ul.option-list li:hover {
  background-color: #eee;
}
.content-list .table .list-wrap .list-item span.item.options .option-toggle {
  position: absolute;
  right: 0;
  cursor: pointer;
}
.content-list .table .list-wrap .list-item span.item.options.active .options-content {
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
}
.content-list .table .list-wrap .list-item span.item.mobile-settings {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
@media (min-width: 1200px) {
  .content-list .table .list-wrap .list-item span.item.mobile-settings {
    padding-bottom: 0;
    border-bottom: 0;
  }
}
.content-list .table .list-wrap .list-item span.item.mobile-settings span.user {
  padding: 10px 15px;
  background-color: #E3EEF5;
  border-radius: 4px;
  display: block;
  width: calc(100% - 55px);
}
@media (min-width: 1200px) {
  .content-list .table .list-wrap .list-item span.item.mobile-settings span.user {
    padding: 0;
    background-color: transparent;
    border-radius: 0;
    width: calc(100% - 45px);
  }
}
.content-list .table .list-wrap .list-item span.item.mobile-settings span.user span {
  display: block;
  line-height: 1.2;
}
.content-list .table .list-wrap .list-item span.item.mobile-settings span.user span.fullname,
.content-list .table .list-wrap .list-item span.item.mobile-settings span.user span a {
  font-size: 1rem;
  font-weight: 600;
  color: #313E47;
  cursor: pointer;
}
@media (min-width: 1200px) {
  .content-list .table .list-wrap .list-item span.item.mobile-settings span.user span.fullname,
.content-list .table .list-wrap .list-item span.item.mobile-settings span.user span a {
    font-size: 0.8rem;
  }
}
@media (min-width: 1200px) and (min-width: 1400px) {
  .content-list .table .list-wrap .list-item span.item.mobile-settings span.user span.fullname,
.content-list .table .list-wrap .list-item span.item.mobile-settings span.user span a {
    font-size: 0.9rem;
  }
}
.content-list .table .list-wrap .list-item span.item.mobile-settings span.user span.email a {
  color: #9F3037;
  font-size: 0.8rem;
  transition: 0.3;
  border-bottom: 1px solid transparent;
  font-weight: 300;
}
@media (min-width: 1200px) {
  .content-list .table .list-wrap .list-item span.item.mobile-settings span.user span.email a {
    font-size: 0.7rem;
  }
}
.content-list .table .list-wrap .list-item span.item.mobile-settings span.user span.email a:hover {
  transition: 0.3s;
  border-color: #9F3037;
}
.content-list .table .list-wrap .list-item span.item.mobile-settings img.user-icon {
  width: 40px;
}
@media (min-width: 1200px) {
  .content-list .table .list-wrap .list-item span.item.mobile-settings img.user-icon {
    width: 30px;
  }
}
.content-list .table .list-wrap .list-item .status {
  position: absolute;
  right: 50px;
  top: 20px;
}
@media (min-width: 1200px) {
  .content-list .table .list-wrap .list-item .status {
    right: unset;
    top: 26px;
    left: 20px;
  }
}
.content-list .table .list-wrap .list-item .status img {
  width: 18px;
}

section.card-wrapper {
  overflow: hidden;
  margin-bottom: 40px;
}

.cards {
  overflow-x: scroll;
  padding-bottom: 10px;
}
.cards::-webkit-scrollbar {
  height: 4px;
}
.cards::-webkit-scrollbar-track {
  background-color: #f7f7f7;
  border-radius: 4px;
}
.cards::-webkit-scrollbar-thumb {
  background-color: #9f2f3735;
  border-radius: 4px;
}
.cards .card-content {
  min-height: 420px;
  min-width: 300px;
  position: relative;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.103);
  border-radius: 4px;
  margin: 10px 15px 10px 10px;
}
.cards .card-content:nth-last-child(1) {
  margin-right: 10px;
}
.cards .card-content .featuredImage {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 200px;
  width: 100%;
}
.cards .card-content .featuredImage .icon-wrapper {
  background-color: #fff;
  border-radius: 4px;
  padding: 4px 7px;
  position: absolute;
  right: 15px;
  top: 15px;
}
.cards .card-content .featuredImage .icon-wrapper img.icon {
  height: 20px;
  width: auto;
}
.cards .card-content .info {
  padding: 25px;
}
.cards .card-content .info span.date-time {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-size: 12px;
  color: #B7B7B7;
}
.cards .card-content .info h4 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
}
.cards .card-content .info p.excerpt {
  margin-top: 0;
  margin-bottom: 20px;
}
.cards .card-content .info .btn-wrapper button {
  margin-right: 15px;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 0.8rem;
}
.cards .card-content .info .btn-wrapper button:nth-last-child(1) {
  margin-right: 0;
}
.cards .card-content .info .btn-wrapper button.draft {
  background-color: #fff;
  color: #313E47;
}
.cards .card-content .info .btn-wrapper button.draft:hover {
  transition: 0.4s;
  background-color: #E3EEF5;
}

.content-details {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 90px 20px;
  box-sizing: border-box;
}
@media (min-width: 992px) {
  .content-details {
    display: flex;
    padding-top: 180px;
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  .content-details {
    max-width: 1000px;
    margin-top: 180px;
    margin-bottom: 0;
    padding: 0;
    position: relative;
  }
}
@media (min-width: 992px) and (min-width: 1200px) and (min-width: 1400px) {
  .content-details {
    max-width: 1200px;
    margin-top: 100px;
  }
}
@media (min-width: 992px) and (min-width: 1200px) and (min-width: 1400px) and (min-width: 1600px) {
  .content-details {
    max-width: 1400px;
  }
}
.content-details .left-side-bar {
  background-color: #313E47;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
@media (min-width: 992px) {
  .content-details .left-side-bar {
    max-width: 350px;
    border-radius: 0;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }
}
.content-details .left-side-bar .inner-wrapper {
  padding: 30px;
  position: relative;
}
.content-details .left-side-bar .inner-wrapper h3 {
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  color: #fff;
  font-size: 24px;
}
.content-details .left-side-bar .inner-wrapper .input-group {
  margin-bottom: 20px;
}
.content-details .left-side-bar .inner-wrapper .input-group label {
  color: #fff;
  font-size: 1rem;
}
.content-details .left-side-bar .inner-wrapper .input-group select {
  background-color: transparent;
  border: 1px solid #f8f8f8;
  color: #fff;
}
.content-details .left-side-bar .inner-wrapper .input-group .full {
  width: 100%;
}
.content-details .left-side-bar .inner-wrapper .input-group .radio-group {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
}
.content-details .left-side-bar .inner-wrapper .input-group .radio-group label {
  margin-bottom: 0;
  color: #CBCBCB;
  font-weight: 400;
}
.content-details .left-side-bar .inner-wrapper .input-group .radio-group input[type=radio], .content-details .left-side-bar .inner-wrapper .input-group .radio-group input[type=checkbox] {
  position: absolute;
  left: 0;
  top: 5px;
  width: auto;
}
.content-details .left-side-bar .inner-wrapper .input-group .allow-permission {
  position: relative;
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #58666f;
  margin-bottom: 10px;
}
.content-details .left-side-bar .inner-wrapper .input-group .allow-permission button.btn {
  position: absolute;
  top: 46px;
  right: 2px;
  padding: 8px;
  font-size: 0.8rem;
}
.content-details .left-side-bar .inner-wrapper .button-group {
  margin-top: 20px;
}
@media (min-width: 992px) {
  .content-details .left-side-bar .inner-wrapper .button-group {
    position: absolute;
    width: calc(100% - 60px);
    bottom: 30px;
  }
}
.content-details .left-side-bar .inner-wrapper .button-group .btn {
  width: 100%;
  margin-bottom: 10px;
}
.content-details .left-side-bar .inner-wrapper .button-group .btn.secondary-btn {
  background-color: #E3EEF5;
  color: #313E47;
  border-color: #E3EEF5;
  transition: 0.3s;
}
.content-details .left-side-bar .inner-wrapper .button-group .btn.secondary-btn:hover {
  background-color: transparent;
  transition: 0.3s;
  color: #E3EEF5;
}
.content-details .left-side-bar .inner-wrapper .button-group .btn-wrapper .btn {
  width: calc(50% - 5px);
  margin-bottom: 0;
}
.content-details .main-content {
  background-color: #f8f8f8;
  padding: 30px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
@media (min-width: 992px) {
  .content-details .main-content {
    width: calc(100% - 350px);
    border-radius: 0;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
}
.content-details .main-content .close-modal {
  cursor: pointer;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 99999;
  filter: invert(0);
}
@media (min-width: 1200px) {
  .content-details .main-content .top-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
}
@media (min-width: 1200px) {
  .content-details .main-content .top-bar .language-selector {
    margin-right: 20px;
  }
}
.content-details .main-content .top-bar .language-selector .country {
  display: inline-block;
  border-radius: 100px;
  padding: 2px;
  background-color: #fff;
  padding-right: 10px;
}
.content-details .main-content .top-bar .language-selector .country img.flag-icon {
  margin-right: 10px;
  width: 35px;
}
.content-details .main-content .top-bar .language-selector .country span {
  font-size: 14px;
  color: #313E47;
  font-weight: 400;
}
.content-details .main-content .top-bar .language-selector .country .icon {
  width: 15px;
  margin-left: 10px;
}
.content-details .main-content .top-bar .permalink {
  margin: 20px 0;
}
@media (min-width: 1200px) {
  .content-details .main-content .top-bar .permalink {
    margin: 0;
  }
}
.content-details .main-content .top-bar .permalink p {
  color: #313E47;
  font-size: 0.9rem;
}
.content-details .main-content .top-bar .permalink p span.link {
  margin-left: 8px;
  color: #9F3037;
  font-style: italic;
  font-size: 0.8rem;
}
.content-details .main-content .top-bar .permalink img.icon {
  margin-left: 15px;
  width: 25px;
}
.content-details .main-content .content .input-group {
  margin-bottom: 20px;
  height: fit-content;
}
.content-details .main-content .content .input-group input,
.content-details .main-content .content .input-group textarea,
.content-details .main-content .content .input-group select {
  border: 0;
}
.content-details .main-content .content .input-group input::placeholder,
.content-details .main-content .content .input-group textarea::placeholder,
.content-details .main-content .content .input-group select::placeholder {
  color: #CBCBCB;
}
.content-details .main-content .content .input-group img.icon {
  position: absolute;
  right: 2px;
  top: 32px;
  height: 34px;
  width: auto;
}
.content-details .main-content .content .input-group.input-only img.icon {
  top: 2px;
}
.content-details .main-content .content .input-group .checkbox-group {
  width: 100%;
  margin-bottom: 10px;
}
.content-details .main-content .content .input-group .checkbox-group input {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}
.content-details .main-content .content .input-group .checkbox-group input:checked {
  background-color: #9F3037;
}
.content-details .main-content .content .input-group .checkbox-group span {
  padding-left: 10px;
  width: calc(100% - 30px);
  font-size: 0.8rem;
  font-weight: 500;
  color: #313E47;
}
.content-details .main-content .content .full {
  width: 100%;
}
.content-details .main-content .content .tabs-wrapper {
  margin-bottom: 10px;
}
.content-details .main-content .content .tabs-wrapper .tab {
  margin-right: 20px;
  cursor: pointer;
  transition: 0.4s;
  border-bottom: 1.5px solid transparent;
}
.content-details .main-content .content .tabs-wrapper .tab:nth-last-child(1) {
  margin-right: 0;
}
.content-details .main-content .content .tabs-wrapper .tab:hover, .content-details .main-content .content .tabs-wrapper .tab.active {
  transition: 0.3s;
  color: #9F3037;
  border-color: #9F3037;
}
.content-details .main-content .content .metadata .checkbox {
  margin-right: 20px;
}
.content-details .main-content .content .metadata .checkbox input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.content-details .main-content .content .metadata .checkbox:nth-last-child(1) {
  margin-right: 0;
}

.modules {
  margin-bottom: 40px;
}
.modules .tab-content {
  padding-top: 20px;
}
.modules .tab-content .col {
  width: 100%;
}
@media (min-width: 1200px) {
  .modules .tab-content .col {
    width: calc(50% - 10px);
  }
}
.modules .accordion-group {
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.modules .accordion-group .acc-header h6 {
  font-size: 20px;
  margin-bottom: 0;
}
.modules .accordion-group .acc-header .icon {
  width: 15px;
  opacity: 0.4;
  transition: 0.2s;
  cursor: pointer;
}
.modules .accordion-group .acc-container {
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: 0.2s;
  padding: 0;
  background-color: #e3eef531;
  border-radius: 10px;
}
.modules .accordion-group .acc-container .industries .full,
.modules .accordion-group .acc-container .module-types .full,
.modules .accordion-group .acc-container .content-element .full,
.modules .accordion-group .acc-container .meta-data .full {
  width: 100%;
}
.modules .accordion-group .acc-container .industries .add,
.modules .accordion-group .acc-container .module-types .add,
.modules .accordion-group .acc-container .content-element .add,
.modules .accordion-group .acc-container .meta-data .add {
  margin-bottom: 30px;
}
.modules .accordion-group .acc-container .industries .add input,
.modules .accordion-group .acc-container .industries .add select,
.modules .accordion-group .acc-container .module-types .add input,
.modules .accordion-group .acc-container .module-types .add select,
.modules .accordion-group .acc-container .content-element .add input,
.modules .accordion-group .acc-container .content-element .add select,
.modules .accordion-group .acc-container .meta-data .add input,
.modules .accordion-group .acc-container .meta-data .add select {
  margin-bottom: 15px;
}
.modules .accordion-group .acc-container .industries .add input::placeholder,
.modules .accordion-group .acc-container .industries .add select::placeholder,
.modules .accordion-group .acc-container .module-types .add input::placeholder,
.modules .accordion-group .acc-container .module-types .add select::placeholder,
.modules .accordion-group .acc-container .content-element .add input::placeholder,
.modules .accordion-group .acc-container .content-element .add select::placeholder,
.modules .accordion-group .acc-container .meta-data .add input::placeholder,
.modules .accordion-group .acc-container .meta-data .add select::placeholder {
  color: #CBCBCB;
}
.modules .accordion-group .acc-container .industries .add .btn,
.modules .accordion-group .acc-container .module-types .add .btn,
.modules .accordion-group .acc-container .content-element .add .btn,
.modules .accordion-group .acc-container .meta-data .add .btn {
  width: 100%;
}
@media (min-width: 992px) {
  .modules .accordion-group .acc-container .industries .add .btn,
.modules .accordion-group .acc-container .module-types .add .btn,
.modules .accordion-group .acc-container .content-element .add .btn,
.modules .accordion-group .acc-container .meta-data .add .btn {
    width: fit-content;
  }
}
.modules .accordion-group .acc-container .industries .list,
.modules .accordion-group .acc-container .module-types .list,
.modules .accordion-group .acc-container .content-element .list,
.modules .accordion-group .acc-container .meta-data .list {
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 4px;
}
.modules .accordion-group .acc-container .industries .list ul li,
.modules .accordion-group .acc-container .module-types .list ul li,
.modules .accordion-group .acc-container .content-element .list ul li,
.modules .accordion-group .acc-container .meta-data .list ul li {
  padding: 10px 15px;
  margin-bottom: 5px;
  background-color: #fff;
  border-radius: 4px;
  font-size: 0.8rem;
  color: #313E47;
  border: 1px solid #f8f8f8;
}
.modules .accordion-group .acc-container .industries .list ul li:nth-last-child(1),
.modules .accordion-group .acc-container .module-types .list ul li:nth-last-child(1),
.modules .accordion-group .acc-container .content-element .list ul li:nth-last-child(1),
.modules .accordion-group .acc-container .meta-data .list ul li:nth-last-child(1) {
  margin-bottom: 0;
}
.modules .accordion-group .acc-container .industries .list ul li img.icon,
.modules .accordion-group .acc-container .module-types .list ul li img.icon,
.modules .accordion-group .acc-container .content-element .list ul li img.icon,
.modules .accordion-group .acc-container .meta-data .list ul li img.icon {
  width: 15px;
  margin-left: 10px;
  cursor: pointer;
  opacity: 0.6;
  transition: 0.4;
}
.modules .accordion-group .acc-container .industries .list ul li img.icon:hover,
.modules .accordion-group .acc-container .module-types .list ul li img.icon:hover,
.modules .accordion-group .acc-container .content-element .list ul li img.icon:hover,
.modules .accordion-group .acc-container .meta-data .list ul li img.icon:hover {
  transition: 0.4s;
  opacity: 1;
}
.modules .accordion-group .acc-container .industries .list ul li .drop-down-info,
.modules .accordion-group .acc-container .module-types .list ul li .drop-down-info,
.modules .accordion-group .acc-container .content-element .list ul li .drop-down-info,
.modules .accordion-group .acc-container .meta-data .list ul li .drop-down-info {
  display: none;
  width: 100%;
  margin-top: 10px;
}
.modules .accordion-group .acc-container .industries .list ul li .drop-down-info.active,
.modules .accordion-group .acc-container .module-types .list ul li .drop-down-info.active,
.modules .accordion-group .acc-container .content-element .list ul li .drop-down-info.active,
.modules .accordion-group .acc-container .meta-data .list ul li .drop-down-info.active {
  display: block;
}
.modules .accordion-group .acc-container .industries .list ul li .drop-down-info .input-wrapper,
.modules .accordion-group .acc-container .module-types .list ul li .drop-down-info .input-wrapper,
.modules .accordion-group .acc-container .content-element .list ul li .drop-down-info .input-wrapper,
.modules .accordion-group .acc-container .meta-data .list ul li .drop-down-info .input-wrapper {
  position: relative;
  margin-bottom: 15px;
}
.modules .accordion-group .acc-container .industries .list ul li .drop-down-info .input-wrapper .add-product,
.modules .accordion-group .acc-container .module-types .list ul li .drop-down-info .input-wrapper .add-product,
.modules .accordion-group .acc-container .content-element .list ul li .drop-down-info .input-wrapper .add-product,
.modules .accordion-group .acc-container .meta-data .list ul li .drop-down-info .input-wrapper .add-product {
  position: absolute;
  top: 0;
  right: 1px;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.modules .accordion-group .acc-container .industries .list ul li .drop-down-info .input-wrapper .add-product img,
.modules .accordion-group .acc-container .module-types .list ul li .drop-down-info .input-wrapper .add-product img,
.modules .accordion-group .acc-container .content-element .list ul li .drop-down-info .input-wrapper .add-product img,
.modules .accordion-group .acc-container .meta-data .list ul li .drop-down-info .input-wrapper .add-product img {
  width: 40px;
  margin: 0;
  padding: 10px;
}
.modules .accordion-group .acc-container .industries .list ul li .drop-down-info .input-wrapper input::placeholder,
.modules .accordion-group .acc-container .module-types .list ul li .drop-down-info .input-wrapper input::placeholder,
.modules .accordion-group .acc-container .content-element .list ul li .drop-down-info .input-wrapper input::placeholder,
.modules .accordion-group .acc-container .meta-data .list ul li .drop-down-info .input-wrapper input::placeholder {
  color: #CBCBCB;
}
.modules .accordion-group .acc-container .industries .list ul li .drop-down-info .child-items,
.modules .accordion-group .acc-container .module-types .list ul li .drop-down-info .child-items,
.modules .accordion-group .acc-container .content-element .list ul li .drop-down-info .child-items,
.modules .accordion-group .acc-container .meta-data .list ul li .drop-down-info .child-items {
  margin: 10px 0;
}
.modules .accordion-group .acc-container .industries .list ul li .drop-down-info .child-items li,
.modules .accordion-group .acc-container .module-types .list ul li .drop-down-info .child-items li,
.modules .accordion-group .acc-container .content-element .list ul li .drop-down-info .child-items li,
.modules .accordion-group .acc-container .meta-data .list ul li .drop-down-info .child-items li {
  border: none;
  background-color: #e3eef531;
}
.modules .accordion-group .acc-container .industries .list ul li .header,
.modules .accordion-group .acc-container .module-types .list ul li .header,
.modules .accordion-group .acc-container .content-element .list ul li .header,
.modules .accordion-group .acc-container .meta-data .list ul li .header {
  cursor: pointer;
}
.modules .accordion-group .acc-container .industries .list ul li .header img.data-toggle,
.modules .accordion-group .acc-container .module-types .list ul li .header img.data-toggle,
.modules .accordion-group .acc-container .content-element .list ul li .header img.data-toggle,
.modules .accordion-group .acc-container .meta-data .list ul li .header img.data-toggle {
  margin-right: 6px;
  width: 12px;
  transition: 0.3s;
  transform: rotate(-90deg);
}
.modules .accordion-group .acc-container .industries .list ul li .header img.data-toggle.active,
.modules .accordion-group .acc-container .module-types .list ul li .header img.data-toggle.active,
.modules .accordion-group .acc-container .content-element .list ul li .header img.data-toggle.active,
.modules .accordion-group .acc-container .meta-data .list ul li .header img.data-toggle.active {
  transition: 0.4s;
  transform: rotate(0);
}
.modules .accordion-group .acc-container .industries .list ul li .header span.title,
.modules .accordion-group .acc-container .module-types .list ul li .header span.title,
.modules .accordion-group .acc-container .content-element .list ul li .header span.title,
.modules .accordion-group .acc-container .meta-data .list ul li .header span.title {
  font-size: 0.8rem;
}
@media (min-width: 1200px) {
  .modules .accordion-group .acc-container .industries .list h6,
.modules .accordion-group .acc-container .module-types .list h6,
.modules .accordion-group .acc-container .content-element .list h6,
.modules .accordion-group .acc-container .meta-data .list h6 {
    font-size: 20px;
  }
}
.modules .accordion-group .acc-container .meta-data .add {
  margin-bottom: 0;
}
.modules .accordion-group .acc-container .meta-data .add .btn {
  font-size: 0.8rem;
  height: 40px;
}
@media (min-width: 992px) {
  .modules .accordion-group .acc-container .meta-data .add .btn {
    margin-right: auto;
    margin-left: 20px;
  }
}
.modules .accordion-group .acc-container .meta-data .list {
  margin-top: 20px;
}
.modules .accordion-group.active-acc .acc-container {
  margin-top: 15px;
  padding: 20px;
  transition: 0.4s;
  opacity: 1;
  visibility: visible;
  height: fit-content;
}
.modules .accordion-group.active-acc .acc-header .icon {
  transition: 0.3s;
  transform: rotate(45deg);
  opacity: 0.8;
}
.modules .module-list {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #eee;
  margin-bottom: 20px;
}
.modules .module-list h6 {
  font-size: 20px;
}
.modules .module-list .module {
  position: relative;
  padding: 15px;
  background-color: #e3eef531;
  border-radius: 10px;
  margin-bottom: 15px;
}
.modules .module-list .module:nth-last-child(1) {
  margin-bottom: 0;
}
.modules .module-list .module .type-wrapper p.name {
  margin-bottom: 0;
  margin-left: 15px;
  font-weight: 500;
  font-size: 1rem;
}
.modules .module-list .module .type-wrapper img.status {
  width: 15px;
  margin-bottom: 4px;
  margin-right: 5px;
}
.modules .module-list .module .type-wrapper img.type {
  height: 20px;
  width: auto;
}
.modules .module-list .module .type-wrapper img.options {
  height: 30px;
  width: auto;
}
.modules .module-list .module .bottom-info {
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  margin-top: 15px;
  border: 1px solid #f8f8f8;
}
.modules .module-list .module .bottom-info span {
  color: #313E47;
  font-size: 0.8rem;
}

html {
  scroll-behavior: smooth !important;
}

.dot {
  position: relative;
  width: 15px;
  height: 15px;
  margin: 0 2px;
  display: inline-block;
}

.dot:first-child:before {
  animation-delay: 0ms;
}

.dot:first-child:after {
  animation-delay: 0ms;
}

.dot:last-child:before {
  animation-delay: 200ms;
}

.dot:last-child:after {
  animation-delay: 200ms;
}

.dot:before {
  content: "";
  position: absolute;
  left: 0;
  width: 15px;
  height: 15px;
  animation-name: dotHover;
  animation-duration: 900ms;
  animation-timing-function: cubic-bezier(0.82, 0, 0.26, 1);
  animation-iteration-count: infinite;
  animation-delay: 100ms;
  background: #9F3037;
  border-radius: 100%;
}

.dot:after {
  content: "";
  position: absolute;
  z-index: -1;
  background: black;
  box-shadow: 0px 0px 1px black;
  opacity: 0.2;
  width: 100%;
  height: 3px;
  left: 0;
  bottom: -2px;
  border-radius: 100%;
  animation-name: dotShadow;
  animation-duration: 900ms;
  animation-timing-function: cubic-bezier(0.82, 0, 0.26, 1);
  animation-iteration-count: infinite;
  animation-delay: 100ms;
}

@keyframes dotShadow {
  0% {
    transform: scaleX(1);
  }
  50% {
    opacity: 0;
    transform: scaleX(0.6);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes dotHover {
  0% {
    top: 0px;
  }
  50% {
    top: -50px;
    transform: scale(1.1);
  }
  100% {
    top: 0;
  }
}

.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  min-height: 300px;
  padding: 24px;
}

.ck-focused.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline{
  min-height: 300px;
  padding: 24px;
}

/*# sourceMappingURL=theme.css.map */
